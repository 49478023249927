import React from 'react'
import { getCurrentLangKey } from 'ptz-i18n'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import Layout from '../../components/Layout/Layout'
import layoutStyles from '../../components/Layout/Layout.module.scss'
import componentStyles from './CasesPage.module.scss'
import { getConfigByLanguage } from '../../utils/common-utils'
import CasesCard from '../../components/CasesCard/CasesCard'

const CasesPage = ({ location }) => {
  const { langs, defaultLangKey } = useSiteMetadata().languages
  const url = location.pathname
  const language = getCurrentLangKey(langs, defaultLangKey, url)
  const config = getConfigByLanguage(language)
  const {
    title,
    reducingUnfulfilledContracts,
    increaseOnlineAdvertising,
    deliverMoreCampaigns,
    boostSTR,
    whiteLabelForecasting,
    seo,
  } = config.cases
  const list = [
    {
      title: reducingUnfulfilledContracts.title,
      url: reducingUnfulfilledContracts.url,
    },
    {
      title: increaseOnlineAdvertising.title,
      url: increaseOnlineAdvertising.url,
    },
    {
      title: deliverMoreCampaigns.title,
      url: deliverMoreCampaigns.url,
    },
    {
      title: boostSTR.title,
      url: boostSTR.url,
    },
    {
      title: whiteLabelForecasting.title,
      url: whiteLabelForecasting.url,
    },
  ]

  return (
    <Layout language={language} location={location} seo={seo}>
      <div className={layoutStyles.container}>
        <h1 className={componentStyles.title}>{title}</h1>
        <section className={componentStyles.casesList}>
          {list.map(({ title, url }, index) => (
            <CasesCard title={title} url={url} key={index} />
          ))}
        </section>
      </div>
    </Layout>
  )
}

export default CasesPage
